import firebase from 'firebase'
const config = {
  apiKey: 'AIzaSyAqcoXz2VV1XOL4Ti24s093LjZBg2VI9fw',
  authDomain: 'compotinder.firebaseapp.com',
  databaseURL: 'https://compotinder.firebaseio.com',
  projectId: 'compotinder',
  storageBucket: 'compotinder.appspot.com',
  messagingSenderId: '465494893131',
  appId: '1:465494893131:web:17a997e03b9c1565f8a3ce'
}
firebase.initializeApp(config)
export default firebase
