import React from 'react'
import seedrandom from 'seedrandom'
import _ from 'lodash'

const WIDTH = 320
const HEIGHT = 4 * WIDTH / 3

const BIGSIZE = WIDTH + HEIGHT
const NUM_SHAPE_TYPES = 3
const MIN_LINE_WIDTH = 2
const MAX_LINE_WIDTH = 20

function RandomLine (rng, color) {
  const lineType = rng() * 5
  const lineOrientation = rng() * 2

  var angle = Math.floor(lineOrientation) * Math.PI / 2

  var linePosition = {
    x: WIDTH / 2,
    y: HEIGHT / 2
  }

  if (lineType > 1) {
    linePosition.x = rng() * WIDTH
    linePosition.y = rng() * HEIGHT
  }

  if (lineType > 3) {
    angle = rng() * Math.PI
  }

  const direction = {
    x: Math.cos(angle),
    y: Math.sin(angle)
  }

  const strokeWidth = MIN_LINE_WIDTH * rng() * (MAX_LINE_WIDTH - MIN_LINE_WIDTH)

  return (
    <line
      x1={linePosition.x - BIGSIZE * direction.x}
      y1={linePosition.y - BIGSIZE * direction.y}
      x2={linePosition.x + BIGSIZE * direction.x}
      y2={linePosition.y + BIGSIZE * direction.y}
      stroke={color}
      style={{ strokeWidth: strokeWidth }}
    />
  )
}

function RandomShape (rng, color) {
  const shapeType = rng() * NUM_SHAPE_TYPES
  if (shapeType < 1) {
    return RandomCircle(rng, color)
  } else if (shapeType < 2) {
    return RandomSquare(rng, color)
  } else {
    return RandomTriangle(rng, color)
  }
}

function RandomSquare (rng, color) {
  const size = rng() * HEIGHT
  const position = {
    x: rng() * WIDTH,
    y: rng() * HEIGHT
  }

  console.log(size, position)

  return (
    <rect
      x={position.x - WIDTH / 2}
      y={position.y - HEIGHT / 2}
      width={size}
      height={size}
      fill={color}
    />
  )
}

function RandomCircle (rng, color) {
  const center = {
    x: rng() * WIDTH,
    y: rng() * HEIGHT
  }

  const radius = rng() * HEIGHT / 2

  return (
    <circle
      cx={center.x}
      cy={center.y}
      r={radius}
      fill={color}
    />
  )
}

function RandomTriangle (rng, color) {
  const ps = []
  ps.push(rng() * WIDTH)
  ps.push(rng() * HEIGHT)
  ps.push(rng() * WIDTH)
  ps.push(rng() * HEIGHT)
  ps.push(rng() * WIDTH)
  ps.push(rng() * HEIGHT)
  return (
    <polygon
      points={ps.reduce((p, c) => p + ' ' + c, '')}
      fill={color}
    />
  )
}

export default function RandomComposition (props) {
  const rng = seedrandom(props.seed)
  let palette = _.clone(props.palette)

  const color1 = palette[Math.floor(rng() * palette.length)]
  palette = _.pull(palette, color1)
  const color2 = palette[Math.floor(rng() * palette.length)]
  palette = _.pull(palette, color2)
  const color3 = palette[Math.floor(rng() * palette.length)]
  palette = _.pull(palette, color3)

  return (
    <svg
      width={WIDTH}
      height={HEIGHT}
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
    >
      <rect width='100%' height='100%' fill={color1} />
      {RandomShape(rng, color2)}
      {RandomLine(rng, color3)}
      <rect width='100%' height='100%' fill='none' stroke='black' strokeWidth={10} />
    </svg>
  )
}
