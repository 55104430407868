import React from 'react'
import RandomComposition from './RandomComposition'
import './App.css'
import firebase from './firebase'
import uuidv1 from 'uuid/v1'
import Color from 'color'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

let guid = JSON.parse(window.localStorage.getItem('guid'))
if (!guid) {
  guid = uuidv1()
  window.localStorage.setItem('guid', JSON.stringify(guid))
}

class App extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      seed: JSON.parse(window.localStorage.getItem('seed')) || 1,
      likes: [],
      color: {
        brightHue: 150,
        brightValue: 90,
        brightSaturation: 90,
        grayValue: 50,
        graySaturation: 10
      }
    }

    this.handleChangeImage = this.handleChangeImage.bind(this)
    this.handleLike = this.handleLike.bind(this)
    this.handleDislike = this.handleDislike.bind(this)
  }

  handleChangeImage () {
    console.log('aaaaa')
    window.localStorage.setItem('seed', this.state.seed + 1)
    this.setState({ seed: this.state.seed + 1 })
  }

  handleDislike () {
    this.handleChangeImage()
  }

  handleLike () {
    const likes = this.state.likes
    likes.push(this.state.seed)
    firebase.database().ref('v0/users/' + guid).set({
      likes: likes
    })
    this.setState({ likes: likes })
    this.handleChangeImage()
  }

  render () {
    const palette = [
      Color.hsv(this.state.color.brightHue, this.state.color.brightSaturation, this.state.color.brightValue),
      Color.hsv(this.state.color.brightHue + 90, this.state.color.graySaturation, this.state.color.grayValue),
      Color.hsv(this.state.color.brightHue - 90, this.state.color.graySaturation, this.state.color.grayValue)
    ]
    console.log(Object.assign({}, this.state, { color: { brightHue: 100 } }))
    return (
      <div className='App'>
        <div>
          <RandomComposition seed={this.state.seed} palette={palette} />
        </div>
        <div style={{ margin: 50, marginTop: 10, marginBottom: 30 }}>
          brightHue
          <Slider value={this.state.color.brightHue} onChange={(v) => this.setState({ color: Object.assign({}, this.state.color, { brightHue: v }) })} min={0} max={360} />
          brightValue
          <Slider value={this.state.color.brightValue} onChange={(v) => this.setState({ color: Object.assign({}, this.state.color, { brightValue: v }) })} min={0} max={100} />
          brightSaturation
          <Slider value={this.state.color.brightSaturation} onChange={(v) => this.setState({ color: Object.assign({}, this.state.color, { brightSaturation: v }) })} min={0} max={100} />
          grayValue
          <Slider value={this.state.color.grayValue} onChange={(v) => this.setState({ color: Object.assign({}, this.state.color, { grayValue: v }) })} min={0} max={100} />
          graySaturation
          <Slider value={this.state.color.graySaturation} onChange={(v) => this.setState({ color: Object.assign({}, this.state.color, { graySaturation: v }) })} min={0} max={100} />
        </div>
        <button style={{ width: '100%', height: 50 }} onClick={this.handleChangeImage}>Randomize</button>
      </div>
    )
  }
}

export default App
